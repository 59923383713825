@charset "UTF-8";
/* CSS Document */
@import "../common/extend";

// ------------------------------------
// ####################################
//  #Common style
// ####################################
// ------------------------------------

$dir-common: $dir + "common"; 
/* Reset */
@include reset;

.wrap {
    width: 100%;
    max-width: $site_size;
    //position: relative;
    margin: 0 auto;
}

body {
  width: 100%;
  font-feature-settings: "palt";
  @include fo-go;
  //line-height: $line-height;
  background: #fff;
  font-size: $basefont;
  color: $black;
}
main{
    margin-bottom: 60px;
    &#top{
        margin-bottom: 0;
    }
    &#information{
        margin-bottom: 0;
    }
}


a {
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: $black;
    &:hover{text-decoration: none; opacity: 0.8; cursor: pointer;}
    &:link{text-decoration: none;}
    &:visited{text-decoration: none;}
}


.inner {
  max-width: $site_size;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  @include sitesize {
    padding: 0 3%;
  }
}

.pc {display: block;}
.pc-flex {display: flex;}
.pc-ib {display: inline-block;}

.sp,
.sp-flex,
.sp-ib {
  display: none;
}


@include tablet {
  .sp {display: block;}
  .sp-flex {display: flex;}
  .sp-ib {display: inline-block;}

  .pc,
  .pc-flex,
  .pc-ib {
    display: none;
  }
}

@include sphone {
  .sp {
    display: block;
  }
}

@import './header';
@import './footer';

/* common ------------------------------------------------ */
.contents{}
.k_visual{
    line-height: 0;
    position: relative;
    .icon_scroll{
        @include fo-min;
        font-size: 1.5rem;
        color: #fff;
        padding-right: 90px;
        position: absolute;
        right: 5%;
        bottom: 70px;
        z-index: 3;
        transform:rotate(90deg); 
        &:after{
            content: "";
            display: inline-block;
            width: 80px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            bottom: 0.3em;
            right: 6px;
        }
        @include tablet{
            font-size: 1.1rem;
            padding-right: 40px;
            right: 0;
            bottom: 39px;
            &:after{
                width: 30px;
            }
        }
    }
    img{
        width: 100%;
    }
}
.bg-1{
    background-color: #e1e1e1;
}
.title_1{
    @include fo-maru;
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 2;
    padding: 90px 0 24px;
    margin-bottom: 50px;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 20px;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    @include tablet{
        font-size:1.8rem;
        padding: 45px 3% 12px;
        margin-bottom: 25px;
    }
}
.title_2{
    @include fo-maru;
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 2;
    color: #fff;
    margin-bottom: 50px;
    @include tablet{
        font-size: 1.5rem;
        margin-bottom: 25px;
    }
}
.title_3{
    @include fo-maru;
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    color: #7f7f7f;
    margin: 60px 0 50px;
    @include tablet{
        font-size: 1.5rem;
        margin-bottom: 25px;
        padding: 0 3%;
    }
}
.sub_title{
    @include fo-min;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 30px;
    @include tablet{
        font-size: 1.7rem;
        margin-bottom: 15px;
        padding: 0 3%;
    }
}
.contents_text{
    font-size: 1.5rem;
    line-height: 2.3;
    @include tablet{
        font-size: 1.1rem;
        padding: 0 3%;
    }
}
//.menu_list{
//    padding-bottom: 60px;
//}
.menu_list_item{
    width: 94%;
    max-width: 980px;
    margin: 0 auto 48px;
    img{
        width: 100%;
    }
    @include flex;
    @include tablet{
        width: 86%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .menu_img{
        width: 50%;
        @include tablet{
            width: 100%;
            margin-bottom: 20px;
        }
        img{
            width: 100%;
        }
    }
    .menu_info{
        width: 50%;
        padding-left: 8%;
        box-sizing: border-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        @include tablet{
            width: 100%;
            padding-left: 0;
        }
        .menu_name{
            @include fo-min;
            font-size: 2.6rem;
            font-weight: bold;
            line-height: 1.7;
            margin-bottom: 20px;
            @include tablet{
                font-size: 1.3rem;
                margin-bottom: 10px;
            }
        }
        .menu_value{
            @include fo-min;
            font-size: 1.8rem;
            color: #7f7f7f;
            margin-bottom: 30px;
            @include tablet{
                font-size: 1.2rem;
                margin-bottom: 15px;
            }
        }
        .menu_text{
            font-size: 1.5rem;
            line-height: 2.4;
            @include tablet{
                font-size: 1.1rem;
                margin-bottom: 15px;
            }
        }
        .menu_link_btn{
            @include fo-min;
            font-size: 1.8rem;
            color: #7f7f7f;
            margin-top: auto;
            padding-right: 120px;
            align-self: flex-end;
            position: relative;
            &:after{
                content: "";
                display: block;
                width: 105px;
                height: 9px;
                background: url("../../img/arrow_1.png") no-repeat right bottom;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: 2px;
            }
            &:hover:after{
                transition: all 0.3s ease-out;
                right: -5px;
            }
            
            @include tablet{
                font-size: 1.3rem;
                padding-right: 80px;
                &:after{
                    width: 70px;
                }
            }
            a{
                display: block;
                color: #7f7f7f;
            }
        }
    }
}
.access{
        font-size: 1.5rem;
        line-height: 2.2;
        width: 94%;
        max-width: 660px;
        margin: 0 auto;
        @include flex;
        @include tablet{
            width: 86%;
            font-size: 1.1rem;
        }
        .access_title{
            font-weight: bold;
            width: 80px;
            margin-bottom: 20px;
            text-align-last: justify;
            @include tablet{
                width: 50px;
            }
        }
        .access_info{
            width: calc(100% - 80px);
            margin-bottom: 20px;
            padding-left: 36px;
            box-sizing: border-box;
            @include tablet{
                padding-left: 14px;
            }
        }
    }
.access_map{
    position: relative;
    padding-bottom: 30%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
// modal--------------------------
.m_lock{
    height: 100vh;
    overflow-y: hidden;
}
.modal {
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  .modal__bg {
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    position: absolute;
    width: 100%;
  }
  .modal-close{}
  .modal__content {
    text-align: right;
    width: 90%;
    max-width: 1200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    p {
      img {
          width: 100%;
      }
    }
    @include queryUp(769px) {
        height: 90vh;
        overflow: scroll;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display:none;
        }
    }
    @include tablet{
        .modal__content{
            width: 75%;
        }
        height:70vh;
        overflow: scroll;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display:none;
        }
    }
  }
}

.lead-block{
  font-size: 90%;
  margin-top: 30px;
}

.modal-close-btn {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;

  span::before,
  span::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 14%;
    width: 70%;
    height: 2px;
    background: #fff;
  }

  span::before {
    transform: rotate(-45deg);
  }

  span::after {
    transform: rotate(45deg);
  }
}

/* contact ------------------------------------------------ */
/* information ------------------------------------------------ */
/* lunch ------------------------------------------------ */
/* menu-1 ------------------------------------------------ */
/* menu-2 ------------------------------------------------ */
.swiper-pagination{
    text-align: left;
}
.swiper-container-horizontal>.swiper-pagination-bullets{
    bottom: 36px;
    left: 3.21%;
    width: 100%;
    @include tablet{
        bottom: 18px;
    }
}
.swiper-pagination-bullet {
    width: 6%;
    height: 2px;
    border-radius: 0;
    background: #999;
    opacity: .2;
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: #d3a440;
}