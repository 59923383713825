@charset 'utf-8';

/* footer ------------------------------------------------ */
footer{
    //margin-top: 60px;
    .bnr_area{
        background-color: #e1e1e1;
        .footer_info{
            width: 94%;
            max-width: 800px;
            padding: 96px 0 48px;
            margin: 0 auto;
            @include flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            .f_bnr{
                width: 47%;
                max-width: 375px;
                a{
                    display: block; 
                    overflow: hidden;
                    img{ 
                        width: 100%;
                        transition: all 0.3s ease-out;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                }
            }
            @include tablet{
                width: 78.13%;
                padding: 8% 0 4%;
            }
        }
    }
    .f_logo{
        width: 170px;
        margin: 0 auto;
        padding-bottom: 48px;
        @include tablet{
            width: 26%;
            min-width: 120px;
            padding-bottom: 8%;
        }
        img{
            width: 100%;
        }
    }
    .copy{
        text-align: center;
        font-size: 1.2rem;
        padding: 1em;
        @include tablet{
            font-size: 1rem;
        }
    }
}
