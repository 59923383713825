@charset 'utf-8';

/* header ------------------------------------------------ */
header{
    width: 100%;
    max-width: $site_size;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    .logo{
        //float: left;
        width: 170px;
        position: absolute;
        top: 0;
        left: 0;
        //padding: 3.21% 0 0 3.21%;
        padding: 2.9% 0 0 3.21%;
        z-index: 2;
        img{
            width: 100%;
        }
    }
    #header_nav{
        .h_pc{display: block;}
        .h_sp{display: none;}
        text-align: right;
        @include fo-maru;
        position: absolute;
        top: 0;
        right: 0;
        //float: right;
        padding: 3.21% 3.21% 0 0;
        .gnav{
            .gnav_item_list{
                display: flex;
                .gnav_item{
                    //min-width: 120px;
                    text-align: center;
                    font-size: 1.7rem;
                    line-height: 1;
                    margin: 0 15px;
                    &:nth-child(3){
                        //min-width: 154px;
                        position: relative;
                        >ul{
                            position: absolute;
                            width: 200px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    a{
                        display: block;
                        color: #fff;
                        &:hover{ color: #d3a440; }
                    }
                    span{
                        font-size: 1.2rem;
                    }
                    .gnav_subtitle{}
                    .gnav_subitem{ display: none; }
                }
                .gnav_subitem{
                    padding-bottom: 10px;
                    margin: 20px 0 10px;
                    border-bottom: 1px solid #fff;
                }
            }
        }
    }
    
    //@include queryDown(1000px){
    @include tablet{
        .logo{
            width: 140px;
            padding-top: 3%;
        }
        #header_nav{
            position: absolute;
            .h_pc{display: none;}
            .h_sp{display: block;}
            //&.active{ background-color: #eddbb3; }
            width: 100%;
            padding: 0;
            #open{
                margin: 3.21% 3.21% 3.21% 0;
            }
            #nav{ 
                width: 100%;
                background-color: #eddbb3;
                padding-top: 55px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
             }
            .gnav{
                .gnav_item_list{
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    padding: 36px 18px;
                    .gnav_item{
                        width: 100%;
                        text-align: left;
                        font-size: 1.4rem;
                        line-height: 1.6;
                        margin: 0;
                        border-bottom: 1px solid #fff;
                        a{ padding: 0.7em 3.21%; }
                        span{
                            font-size: 1.4rem;
                            margin-left: 1em;
                        }
                        &:first-child{
                            border-top: 1px solid #fff;
                        }
                        &:nth-child(3){
                            //min-width: 154px;
                            position: static;
                            >ul{
                                position: static;
                                width: 100%;
                                transform: none;
                            }
                        }
                    }
                    .btn_1{
                        position: relative;
                        &:before{
                            content: '';
                            display: block;
                            width: 10px;
                            height: 10px;
                            z-index: 1;
                            border-right: 1px solid #d3a440;
                            border-bottom: 1px solid #d3a440;
                            -webkit-transform: translate(0, -50%) rotate(-45deg);
                            transform: translate(0, -50%) rotate(-45deg);
                            position: absolute;
                            top: 20px;
                            right: 26px;
                        }
                    }
                    .btn_2{
                        position: relative;
                        &:before,
                        &:after{
                            content: '';
                            display: block;
                            width: 16px;
                            height: 1px;
                            position: absolute;
                            top: 20px;
                            right: 22px;
                            bottom: 0;
                            z-index: 3;
                            background-color: #d3a440;
                            transition: all .4s;
                        }
                        &:before{
                            transform: rotate(-90deg);
                        }
                    }
                    .btn_2.active{
                        &:before{
                            transform: rotate(0);
                        }
                    }
                    .gnav_subitem{
                        padding-left: 2em;
                        padding-bottom: 0;
                        margin: 0;
                        border: none;
                        border-top: 1px solid #fff;
                    }
                }                
            }
        }
        .sp_menu_btn_area{}
        .sp_menu_btn,
        .sp_menu_btn span {
            display: inline-block;
            transition: all .4s;
            box-sizing: border-box;
        }
        .sp_menu_btn {
            background-color: #666;
            border-radius: 5px;
            position: relative;
            width: 30px;
            width: 40px;
            height: 24px;
            height: 40px;
            span{
                position: absolute;
                left: 0;
                width: 70%;
                height: 2px;
                background-color: #fff;
                border-radius: 3px;
                &:nth-of-type(1){top: 10px; left: 6px}
                &:nth-of-type(2){top: 19px; left: 6px}
                &:nth-of-type(3){bottom: 10px; left: 6px}
            }
        }
        .active{
            .sp_menu_btn{
                span{
                    &:nth-of-type(1){
                        -webkit-transform: translateY(9px) rotate(-45deg);
                        transform: translateY(9px) rotate(-45deg);
                    }
                    &:nth-of-type(2){
                        opacity: 0;
                    }
                    &:nth-of-type(3){
                        -webkit-transform: translateY(-9px) rotate(45deg);
                        transform: translateY(-9px) rotate(45deg);
                    }
                }
            }
        }
    }
}